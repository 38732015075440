<template>
  <div class="box-content">
    <mcp-modal :show="show" @closeModal="showModal">
      <detail :bank="bank" />
    </mcp-modal>
    <search :makeSearch="makeSearch">
      <template #buttons>
        <router-link
          class="button is-outlined is-small"
          to="/operateurs/create"
        >
          Ajouter un opérateur
        </router-link>
        <button @click="refreshBanks" class="button is-outlined is-small">
          Actualiser
        </button>
      </template>
    </search>
    <data-table
      :columns="columns"
      :loading="loading"
      :counter="banks.length"
      :meta="meta"
      :navigationFunction="navigationFunction"
    >
      <template #body>
        <tbody>
          <tr v-for="(bank, key) in banks" :key="key">
            <td>{{ bank.code }}</td>
            <td>{{ bank.handlerclass }}</td>
            <td>{{ bank.email }}</td>
            <td>{{ bank.phone }}</td>
            <td>{{ bank.ifu }}</td>
            <td>{{ bank.created_at }}</td>
            <td>
              <div class="d-flex">
                <mcp-tooltip title="Détails">
                  <i class="fa-solid fa-info" @click="detailOperator(bank)"></i>
                </mcp-tooltip>
                <mcp-tooltip title="Modifier">
                  <i
                    @click="
                      () =>
                        $router.push({
                          name: 'operateurs_edit',
                          params: { id: bank.id },
                        })
                    "
                    class="fa-solid fa-edit"
                  ></i>
                </mcp-tooltip>
                <mcp-tooltip title="Supprimer">
                  <i @click="removeBank(bank)" class="fa-solid fa-remove"></i>
                </mcp-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </data-table>
  </div>
</template>

<script>
import dataTable from "@/components/table/datatable";
import search from "@/components/table/search";
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import mcpModal from "@/components/McpModal";
import detail from "./detail";
export default {
  name: "operateurs",
  components: {
    dataTable,
    search,
    mcpModal,
    detail,
  },
  data: () => ({
    loading: false,
    show: false,
    columns: [
      { name: "code", label: "Code opérateur" },
      { name: "handlerclass", label: "Methode" },
      { name: "email", label: "Email" },
      { name: "phone", label: "Téléphone" },
      { name: "ifu", label: "Numéro d'identification" },
      { name: "created_at", label: "Date de création" },
      { name: "action", label: "Actions" },
    ],
    model: {
      page: 1,
      search: "",
    },
  }),

  computed: {
    ...mapGetters({
      banks: `${TYPES.modules.BANKS}/${TYPES.getters.GET_BANKS}`,
      meta: `${TYPES.modules.BANKS}/${TYPES.getters.GET_BANKS_META}`,
      bank: `${TYPES.modules.BANKS}/${TYPES.getters.GET_BANK}`,
    }),
  },

  mounted() {
    this.getBanks();
  },

  methods: {
    navigationFunction(page) {
      this.current.page = page;
      this.getBanks();
    },

    getBanks() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.BANKS + "/" + TYPES.actions.GET_BANKS}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    makeSearch(search) {
      this.model.page = 1;
      this.model.search = search;
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.BANKS + "/" + TYPES.actions.GET_BANKS}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    refreshBanks() {
      this.loading = true;
      this.model.page = 1;
      this.model.search = "";
      this.$store
        .dispatch(
          `${TYPES.modules.BANKS + "/" + TYPES.actions.GET_BANKS}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    removeBank(bank) {
      if (confirm(`Voulez vous supprimer l'opérateur ${bank.code} ?`)) {
        this.loading = true;
        this.$store
          .dispatch(
            `${TYPES.modules.BANKS + "/" + TYPES.actions.DELETE_BANK}`,
            bank
          )
          .then((res) => {
            if (res) {
              this.loading = false;
              this.$toast.success(`L'opérateur ${bank.code} a été supprimé`);
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },

    showModal() {
      this.show = !this.show;
    },

    getBnak(bank) {
      this.$store
        .dispatch(
          `${TYPES.modules.BANKS + "/" + TYPES.actions.DETAILS_BANK}`,
          bank.id
        )
        .then(() => {})
        .catch(() => {});
    },

    detailOperator(bank) {
      this.getBnak(bank);
      this.showModal();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>