<template>
  <div>
    <h2>Détails d'un opérateur</h2>
    <div class="pt-10">
      <table class="table is-hoverable is-fullwidth is-responsive">
        <thead>
          <th>Titre</th>
          <th>Contenu</th>
        </thead>
        <tbody>
          <tr>
            <td>Code opérateur</td>
            <td>{{ bank.code }}</td>
          </tr>
          <tr>
            <td>Methode</td>
            <td>{{ bank.handlerclass }}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ bank.email }}</td>
          </tr>
          <tr>
            <td>Téléphone</td>
            <td>{{ bank.phone }}</td>
          </tr>
          <tr>
            <td>Numéro d'identification</td>
            <td>{{ bank.ifu }}</td>
          </tr>
          <tr>
            <td>Date de création</td>
            <td>{{ bank.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />
    <div class="pt-10" v-if="bank.properties.length > 0">
      <h2>Autres informations</h2>
      <table class="table is-hoverable is-fullwidth is-responsive">
        <thead>
          <th>Nom</th>
          <th>Valeur</th>
          <th>Description</th>
        </thead>
        <tbody>
          <tr v-for="(item, key) in bank.properties" :key="key">
            <td>{{ item.name }}</td>
            <td>{{ item.value }}</td>
            <td>{{ item.description }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "detailOperator",
  props: {
    bank: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
</style>